<template>
    <div>
        <input hidden type="text" class="form-control" id="ItemGroupId" v-model="ItemGroupId">
        <div class="modal fade" id="itemGroupModal" tabindex="-1"
        aria-labelledby="itemGroupModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="itemGroupModalLabel" class="font-weight-bold">{{ModalTitle}}{{ItemGroupName}}</h4>
                        <a type="button" data-bs-dismiss="modal"
                        aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Nama</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="ItemGroupName" v-model="ItemGroupName" class="font-weight-bold" />
                                    <label id="errorItemGroupName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;">Save</CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close">Close</CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ItemGroupServices from '../Script/ItemGroupServices.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'ItemGroupForm',
    props: ['reload'],
    components: {
    },
    data: function () {
        return {
            ModalTitle : '',
            ItemGroupId: '',
            ItemGroupName: '',
            Error: 0,
            SaveType: '',
        }
    },
    mounted(){

    },
    methods: {
        addClick(){
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Add Item Group: ';
            
            this.ItemGroupName = '';
            this.SaveType = 'Add';
            window.$('#itemGroupModal').modal('show');
        },
        editClick(itemGroupData, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Edit Item Group: ';

            this.ItemGroupId = itemGroupData.item_group_id;
            this.ItemGroupName = itemGroupData.item_group_name;
            this.SaveType = 'Edit';

            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Item Group: ';
            }

            window.$('#itemGroupModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.ItemGroupName == ''){
                this.errorShow('errorItemGroupName');
            }
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const itemGroupData = {
                        item_group_name : this.ItemGroupName
                    };

                    const variables = {
                        data : itemGroupData
                    }

                    ItemGroupServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#itemGroupModal').modal('hide');
                        this.$swal("Info", response.successSave, "success");
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const itemGroupData = {
                        item_group_name : this.ItemGroupName
                    };

                    const variables = {
                        id : this.ItemGroupId.toString(),
                        data : itemGroupData
                    }
                    
                    ItemGroupServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#itemGroupModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    },
}
</script>
