import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class ItemGroupService {
    readItemGroupQuery(){
        var query = `query{
                        GetItemGroup{
                            item_group_id
                            item_group_name
                            created_at
                            last_update
                        }
                    }`;
        return query;
    }

    async addQuery(variables){
        var query = gql`
            mutation($data: item_group_new!){
                CreateItemGroup(New: $data){
                    response
                }
            }
        `;  
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`
            mutation($id: String!, $data: item_group_new!){
                UpdateItemGroup(ItemGroup_Id: $id, New: $data ){
                    response
                }
            }
        `;  
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`
            mutation( $id: String! ){
                DeleteItemGroup( ItemGroup_Id: $id ){
                    response
                }
            }
        `;  
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

}

export default new ItemGroupService();